import axios from '@/libs/axios'

class Game {
  static histories(params) {
    return axios.post('user/game-histories', params)
  }

  static transactions(params) {
    return axios.post('user/transactions', params)
  }

  static async downloadTransactionList(params) {
    const URLparams = new URLSearchParams(Object.entries(params))
    window.open(`${process.env.VUE_APP_API_BASE_URL}user/transactions/export?${URLparams}`, '_blank')
  }

  static async downloadGameHistoryList(params) {
    const URLparams = new URLSearchParams(Object.entries(params))
    window.open(`${process.env.VUE_APP_API_BASE_URL}user/games/history/export?${URLparams}`, '_blank')
  }

  static reportWinLose(params) {
    return axios.post('user/games/win-lose', params)
  }

  static auditList(params) {
    return axios.post('user/games/transactions/audit', params)
  }

  static settle(params) {
    return axios.post('user/games/settle', params)
  }
}
export default Game
